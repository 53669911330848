.search-area {
  margin: 0;

  /* .sub-area {
    display: flex;
    flex-direction: column;
  } */
}

.search-area-search {
  text-align: right;
  margin-top: 20px;
}

.search-area-buttons {
  text-align: right;
}

.select-project {
  width: 18vw;
  margin-left: 1vw;
}

.select-general {
  width: 15vw;
  margin-left: 0.5vw;
}

.button-margin-left {
  margin-left: 8px;
}

.button-margin-right {
  margin-right: 8px;
}

.details-down-row-margin {
  margin-top: 20px;
}

.details-down-button {
  display: flex;
  justify-content: space-between;
}

.details-close-btn {
  margin-top: 30px;
  text-align: right;
}

.icon-weight {
  font-weight: 1000;
}

.table-row-action {
  cursor: pointer;
}

.detail-info-area {
  table {
    margin-left: 0.1vw;
    text-align: left;
    tbody {
      th {
        min-width: 160px;
        font-weight: 700;
      }
      td {
        min-width: 250px;
        padding: 0.5vh 0.5vw;
        vertical-align: middle;

        .ant-upload-list {
          cursor: pointer;
          width: 280px;
        }
      }
      .td-right-button {
        margin-left: 3vw;
      }
    }
  }
}
