.search-area {
  margin: 0;

  .sub-area {
    display: flex;
    flex-direction: column;
  }
}

.search-area-part {
  display: flex;
  align-items: center;
}

.menu-search-area-search {
  text-align: right;
  margin-top: 20px;
}

.menu-search-area-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.select-project {
  width: 18vw;
  margin-left: 1vw;
}

.select-general {
  width: 15vw;
  margin-left: 0.5vw;
}

.button-margin-left {
  margin-left: 8px;
}

.button-margin-right {
  margin-right: 8px;
}

.icon-weight {
  font-weight: 1000;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
