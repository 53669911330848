.target-info {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: lightgray;
  opacity: 0.5;
  justify-content: center;
}

.target-info-list-area {
  height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.datetime-info {
  table {
    margin-left: 0.1vw;
    text-align: left;
    tbody {
      th {
        min-width: 130px;
        font-weight: 700;
      }
      td {
        min-width: 160px;
        padding: 0.2vh 0.5vw;
        vertical-align: middle;
      }
    }
  }
}

.layer-table {
  max-height: 20vh;
  overflow-y: auto;
  white-space: nowrap;
  table {
    margin: auto;
    thead {
      background-color: #fafafa;
      th {
        min-width: 170px;
        font-weight: 700;
        text-align: center;
        border: 1px solid #e7eaef;
      }
    }
    tbody {
      th,
      td {
        min-width: 170px;
        text-align: center;
        border: 1px solid #e7eaef;
      }
    }
  }
}

.bottom-button-area {
  margin-top: 2vh;
}

.column-align-center {
  text-align: center;
}
