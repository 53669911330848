.ant-layout-header {
  text-align: right;
}
.ant-layout-footer {
  padding: 0;
  background-color: darkgray;
  height: 75px;
  text-align: center;
}
.ant-layout-sider {
  background-color: #fff;
  /* line-height: 120px; */
}
.ant-layout-content {
  min-width: 1000px;
  min-height: 500px;
}
