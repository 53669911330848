.centered {
  margin: 0px auto;
  padding: 10vh 5vw;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
}
.title-img {
  width: 250px;
}

.login-main-area {
  margin: 0px auto;
  width: 300px;

  .login-button {
    background-color: #346cee;
    width: 300px;
  }
}

.header-main {
  min-width: 850px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-img {
    cursor: pointer;
    filter: invert(30%);
    height: 30px;
  }

  .user-info-space {
    height: 64px;
    display: flex;
    align-content: flex-end;
    color: #ffffff;
    font-weight: 700;

    .server-status-space {
      margin-right: 1vw;
      align-items: center;

      .server-status-title {
        padding-right: 5px;
        border-right: 2px solid #ffffff;
      }

      .server-status-each {
        padding-left: 5px;
        font-weight: 500;
      }
    }

    .lingual-swich {
      min-width: 80px;
      margin-left: 0.5vw;
    }

    .user-name {
      font-weight: 700;
      margin-right: 1vw;
    }
  }
}

.button-logout {
  margin-top: 1.5vh;
  margin-left: 0.5vw;
  color: #ffffff;
  background-color: #477fe6;
  border: none;
  border-radius: 10px;
}

.health-check-area {
  text-align: center;
  flex-direction: column;

  .title-string {
    font-weight: 800;
  }
}

.green-dot {
  height: 10px;
  width: 10px;
  margin-right: 0.3vw;
  background-color: #29c93e;
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  height: 10px;
  width: 10px;
  margin-right: 0.3vw;
  background-color: #ec281a;
  border-radius: 50%;
  display: inline-block;
}

.account-tooltip-content {
  table {
    tbody {
      th {
        min-width: 120px;
        font-weight: 700;
      }
      td {
        min-width: 130px;
        vertical-align: middle;
      }
    }
  }
}
