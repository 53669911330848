.search-area {
  margin: 0;

  .sub-area {
    display: flex;
    flex-direction: column;
  }
}

.search-area-row-1 {
  margin-bottom: 12px;
}

.search-area-row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-button-area {
  display: flex;
  justify-content: flex-end;
}

.table-row-action {
  cursor: pointer;
}

.date-picker-style {
  width: 240px;
}

.modal-tms-detail-footer-custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button-margin {
    margin-right: 10px;
  }
}
