/* 공통 메시지 요소 */
.custom-notification .ant-message-notice-content,
.custom-message .ant-message-notice-content {
  padding: 14px, 23px, 14px, 23px;
  border-radius: 15px;
  background-color: #252931cc;
  color: #e1e3ea;
  font-size: 15px;
}
.info-message .ant-message-notice-content {
  background-color: #477fe6;
  color: #ffffff;
}
.success-message .ant-message-notice-content {
  background-color: #52c311;
  color: #ffffff;
}
.warn-message .ant-message-notice-content {
  background-color: #ff7f00;
  color: #ffffff;
}
.error-message .ant-message-notice-content {
  background-color: #e65147;
  color: #ffffff;
}

.custom-message .ant-divider {
  margin: 0 5px;
  height: 11px;
  background-color: #e1e3ea;
}

.custom-message svg {
  width: 14px;
  height: 14px;
  fill: #e1e3ea;
}

.custom-message svg:hover {
  fill: #00cfb1;
}

.custom-message .anticon {
  margin: 0 5px 0 0;
}

/* 공통 Confirm 메시지 요소 */
.ant-popover-content {
  .ant-popover-inner {
    border-radius: 15px;
  }
}

.specific-cursor {
  cursor: pointer;
}

/* 테이블 로딩 애니메이션: 로딩 중일 때 "내용 없음" 배경 미표시 */
.table-render-loading-tbody {
  min-height: 150px;
}
