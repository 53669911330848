.search-area {
  margin: 0;

  .sub-area {
    display: flex;
    flex-direction: column;
  }
}

.log-area-title {
  font-weight: 600;
  margin-bottom: 1vh;
}

.log-output-area {
  height: 30vh;
  width: 81vw;
  margin-top: 1vh;
  padding: 0.5vw;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  overflow-y: scroll;
}
.search-area-row-1 {
  margin-bottom: 12px;
}

.search-area-row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-area-column {
  display: flex;
  align-items: center;
}

.search-area-row-2-col-1 {
  margin-right: 10px;
}

.search-area-search {
  text-align: right;
  margin-top: 20px;
}

.copy-area {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 24px -12px;
  margin: 0;
}

.copy-area-part {
  text-align: left;
  display: flex;
  align-items: center;
}

.copy-area-button-part {
  text-align: center;
  margin-top: 2vh;

  .copy-button {
    margin: 0.5vw;
  }
}

.log-job-id-area {
  text-align: left;
  height: 20vh;
  width: 100%;
  padding: 0.8vw;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  overflow-y: scroll;
}

.redo-log-area {
  height: 20vh;
  width: 100%;
  padding: 0.5vw;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  overflow-y: scroll;
}

.select-project {
  width: 18vw;
  margin-left: 1vw;
}

.select-general {
  width: 15vw;
  margin-left: 0.5vw;
}

.button-margin-left {
  margin-left: 8px;
}

.text-align-right {
  text-align: right;
}

.search-button-area {
  display: flex;
  justify-content: flex-end;
}

.search-job-id {
  margin-left: 12px;
}

.db-activity-search-row2 {
  margin-top: 1vh;
}
