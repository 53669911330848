.search-area {
  margin: 0;
}

.search-area-row-1 {
  margin-bottom: 12px;
}

.search-area-row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-area-column {
  display: flex;
  align-items: center;
}

.search-area-row-2-col-1 {
  margin-right: 10px;
}

.search-area-search {
  text-align: right;
  margin-top: 20px;
}

.select-project {
  width: 18vw;
  margin-left: 1vw;
}

.select-general {
  width: 15vw;
  margin-left: 0.5vw;
}

.select-general-detail-modal {
  width: 20vw;
  margin-left: 0.5vw;
}

.button-margin-left {
  margin-left: 8px;
}

.text-align-right {
  text-align: right;
}

.date-picker {
  width: 200px;
}

.search-button-area {
  display: flex;
  justify-content: flex-end;
}

.search-user {
  margin-left: 12px;
}

.table-row-action {
  cursor: pointer;
}
.row-bottom-margin {
  margin-bottom: 10px;
}

.row-top-margin {
  margin-top: 10px;
}
