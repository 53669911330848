[data-theme="compact"] .ant-advanced-search-form,
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-form {
  max-width: none;
}
.search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
[data-theme="dark"] .ant-advanced-search-form {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid #434343;
  padding: 24px;
  border-radius: 2px;
}
[data-theme="dark"] .search-result-list {
  border: 1px dashed #434343;
  background: rgba(255, 255, 255, 0.04);
}

.device-search-area {
  .button-area {
    display: flex;
    justify-content: space-between;
  }
}

.assign-modal-button {
  margin-top: 2vh;
  text-align: right;
}

.button-margin-left {
  margin-left: 8px;
}

.device-return-select {
  width: 350px;
}

.table-row-action {
  cursor: pointer;
}

.assign-info {
  /* max-height: 10vh; */
  white-space: nowrap;
  table {
    margin-left: auto;
    margin-right: auto;
    thead {
      background-color: #fafafa;
      th {
        font-weight: 700;
        text-align: center;
        border: 1px solid #e7eaef;
      }
    }
    tbody {
      th,
      td {
        min-width: 6.5vw;
        text-align: center;
        border: 1px solid #e7eaef;
      }
    }
  }

  .no-data-width {
    min-width: 600px;
  }
}
