.header-mobile {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  height: 64px;

  img {
    margin-left: -5vw;
    cursor: pointer;
    filter: invert(30%);
    height: 4vh;
  }

  .user-info-space {
    display: flex;
    align-content: flex-end;
    color: #ffffff;
    font-weight: 700;

    .server-status-space {
      margin-right: 1vw;
      align-items: center;

      .server-status-each {
        padding-left: 1vw;
        font-weight: 500;
      }
    }

    .user-name {
      font-weight: 700;
      margin-right: 1vw;
    }
  }

  .button-logout {
    color: #ffffff;
    background-color: #477fe6;
    border: none;
    border-radius: 10px;
  }

  .account-tooltip-content {
    table {
      tbody {
        th {
          min-width: 100px;
          font-weight: 700;
        }
        td {
          min-width: 120px;
          vertical-align: middle;
        }
      }
    }
  }
}

.main-area {
  width: 80vw;
  table {
    margin-left: 20px;
    text-align: left;
    tbody {
      /* background-color: #ffffff; */

      th {
        min-width: 120px;
        font-weight: 700;
      }
      td {
        min-width: 300px;
        padding: 3px 5px;
        vertical-align: middle;
      }
    }
  }
}
