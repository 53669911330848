.search-area {
  padding: 24px -12px;
  margin: 0;
}

.search-area-part {
  display: flex;
  align-items: center;
}

.search-area-select {
  width: 20vw;
  margin-left: 0.5vw;
}

.search-area-button {
  margin-top: 24px;
  text-align: right;
}

.copy-area {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 24px -12px;
  margin: 0;
}

.copy-area-part {
  text-align: left;
  display: flex;
  align-items: center;
}

.copy-area-button-part {
  text-align: center;
  margin-top: 2vh;

  .copy-button {
    margin: 0.5vw;
  }
}

.result-area {
  width: 100vw;
  height: 15vw;
  white-space: pre-line;
  overflow-y: auto;
}

.annotation-detail-col1 {
  width: 40%;

  .cesium-widget-credits {
    display: none !important;
  }

  canvas {
    width: 350px;
    height: 400px;
  }
}

.annotation-detail-col2 {
  width: 60%;
}

.annotation-detail-scroll {
  height: 10vh;
  overflow-y: scroll;
  margin-top: 0;
  margin-bottom: 0em;
}

.annotation-detail-scroll-null {
  height: 10vh;
}

.content-line {
  min-height: 10vh;

  .annotation-detail-table {
    margin-left: 20px;
    text-align: left;
    tbody {
      background-color: #ffffff;

      th {
        min-width: 120px;
        font-weight: 700;
      }
      td {
        min-width: 300px;
        padding: 3px 5px;
        vertical-align: middle;
      }
    }
  }
}

.annotation-detail-info-area {
  text-align: left;
  margin-left: 20px;

  table {
    thead,
    tbody {
      vertical-align: middle;
      th {
        font-weight: 700;
      }
    }
  }
}

.annotation-detail-info-table {
  max-height: 12vh;
  margin-left: 0;
  overflow: auto;
  white-space: nowrap;
  table {
    thead {
      background-color: #fafafa;
      th {
        font-weight: 700;
        text-align: center;
        border: 1px solid #e7eaef;
      }
    }

    tbody {
      th,
      td {
        text-align: center;
        min-width: 10px;
        border: 1px solid #e7eaef;
      }
    }
  }
}

.min-width-td {
  table {
    min-width: 100%;
    th,
    td {
      min-width: 40px;
    }
    .specific-align {
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    .click-td {
      color: rgba(0, 0, 255, 0.741);
      cursor: pointer;
      text-decoration: underline;
    }
    .cutfill-weight {
      font-weight: 500;
    }
  }
}

.content-line-loc-table {
  height: 15vh;
  overflow: scroll;
  margin-top: 0;
  margin-bottom: 0em;
}

.annotation-job-detail-footer {
  margin-top: 2vh;
  text-align: right;
}

.button-margin-right {
  margin-right: 8px;
}

.modal-download-row {
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
}

.devider-margin-custom {
  margin-bottom: 0px !important;
}
