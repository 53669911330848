.search-area {
  margin: 0;
}

.search-area-row-1 {
  margin-bottom: 12px;
}

.search-area-row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-area-column {
  display: flex;
  align-items: center;
}

.search-area-row-1-col {
  margin-right: 10px;
}
.col-3-row1 {
  width: 40px;
}
.col-3-row2-checkbox {
  display: flex;

  .ant-checkbox-group-item {
    width: 100px;
  }
}

.search-area-search {
  text-align: right;
  margin-top: 20px;
}

.select-project {
  width: 18vw;
  margin-left: 1vw;
}

.select-general {
  width: 15vw;
  margin-left: 0.5vw;
}

.select-general-detail-modal {
  width: 20vw;
  margin-left: 0.5vw;
}

.button-margin-left {
  margin-left: 8px;
}

.col-align-right {
  text-align: right;
}

.date-picker {
  width: 18vw;
}

.search-button-area {
  display: flex;
  justify-content: flex-end;
}

.search-user {
  margin-left: 12px;
}

.table-row-action-dump {
  cursor: pointer;
}
.table-row-action-no-dump {
  cursor: not-allowed;
}
.row-bottom-margin {
  margin-bottom: 10px;
}

.row-top-margin {
  margin-top: 10px;
}

.route-info-detail-col1 {
  width: 40%;
}

.route-info-detail-col2 {
  width: 60%;
  padding-left: 20px;

  .cesium-widget-credits {
    display: none !important;
  }

  canvas {
    width: 400px;
    height: 400px;
  }
}
